<template>
  <v-layout 
    wrap
    v-if="menusStatus"
  >
    <v-flex xs12 class="title">
      <h2>
        Menus PDF [{{ menusStatus.int }}min.]
      </h2>
    </v-flex>
    <v-flex xs4 class="item">
      {{ menusStatus.error ? `Error: ${menusStatus.error}` : 'No Error' }}
    </v-flex>
    <v-flex xs4 class="item">
      {{ menusStatus.resultCount }} / {{ menusStatus.enterpriseCount }}
    </v-flex>
    <v-flex xs4  class="item">
      ⏱ {{ menusStatus.time }}
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    menusStatus: null,
  }),
  mounted () {
    this.handleGetData()
  },
  methods: {
    getIntervalInHours (v) {
      return (((new Date() - v) / 60000)/60)|0
    },
    handleGetData () {
      const url = 'https://cm-dtouch-api.dtouch.es/resultExternalPDF'
      axios.get(url)
        .then(response => {
          if (!response || !response.data) return
          const { enterpriseCount, resultCount, error, LMD, time } = response.data
          this.menusStatus = {
            enterpriseCount, resultCount, error, LMD, time: time.toString().split('.').shift(),
            int: this.getIntervalInHours (new Date(LMD))
          }
        })
    },
  }
}
</script>
<style scoped>
.title {
  background-color: #ccc;
  text-align: center;
  padding: 20px 10px;
}
.item {
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid #CCCCCC80;
}
.action {
  padding: 10px;
  text-align: right;
}
</style>


